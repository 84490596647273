import { defineMessages } from 'gatsby-plugin-locale';

export const formMessages = defineMessages({
	invalidEmail: {
		id: 'form.invalidEmail',
		defaultMessage: 'Dit email adres is ongeldig',
	},
	requiredField: {
		id: 'form.requiredField',
		defaultMessage: 'Dit veld is verplicht',
	},
	passwordMatch: {
		id: 'form.passwordMatch',
		defaultMessage: 'Dit paswoord is niet hetzelfde',
	},
	passwordMustContainNumeric: {
		id: 'form.passwordMustContainNumeric',
		defaultMessage: 'Dit paswoord moet minstens 1 cijfer hebben',
	},
	passwordMustContainsLength: {
		id: 'form.passwordMustContainsLength',
		defaultMessage: 'Dit paswoord moet minstens {amount} karakters lang zijn',
	},
});
