import { RouteComponentProps } from '@reach/router';
import { Auth } from 'aws-amplify';
import React, { FC, useMemo, useState } from 'react';
import { defineMessages, useIntl } from 'gatsby-plugin-locale';
import { AuthLayout } from './AuthLayout';
import { ErrorMessage } from './components/ErrorMessage';
import { ResetPasswordForm } from './forms/ResetPassword';

enum Steps {
	GET_VERIFICATION_CODE = 'GET_VERIFICATION_CODE',
	GET_VERIFICATION_CODE_DONE = 'GET_VERIFICATION_CODE_DONE',
}

const messages = defineMessages({
	title: {
		id: 'forgotPassword.title_GET_VERIFICATION_CODE',
		defaultMessage: 'Paswoord resetten',
	},
	verificationCodeSuccess: {
		id: 'forgotPassword.verificationCodeSuccess',
		defaultMessage:
			'We hebben je een mail gestuurd met daarin een link om verder te gaan. Je mag dit venster sluiten.',
	},
});

interface Props {
	locale: string;
}

export const ForgotPassword: FC<RouteComponentProps & Props> = ({ locale }) => {
	const { formatMessage: f } = useIntl();
	const [step, setStep] = useState<Steps>(Steps.GET_VERIFICATION_CODE);
	const [error, setError] = useState<any>();

	const StepComponent = useMemo(() => {
		setError(null);
		if (step === Steps.GET_VERIFICATION_CODE) {
			return (
				<ResetPasswordForm
					onSubmit={async ({ username }) => {
						try {
							setError(null);

							await Auth.forgotPassword(username);
							setStep(Steps.GET_VERIFICATION_CODE_DONE);
						} catch (err) {
							console.error(err);
							setError(err);
						}
					}}
				/>
			);
		} else if (step === Steps.GET_VERIFICATION_CODE_DONE) {
			return f(messages.verificationCodeSuccess);
		}
	}, [step, locale]);

	return (
		<AuthLayout title={f(messages.title)}>
			<ErrorMessage error={error} type={step} />
			{StepComponent}
		</AuthLayout>
	);
};
