import React, { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { defineMessages, useIntl } from 'gatsby-plugin-locale';
import { Link } from 'gatsby-plugin-locale';
import { formMessages } from '../../../utils/intl';

const messages = defineMessages({
	email: {
		id: 'resetPassword.email',
		defaultMessage: 'Email',
	},
	sendCode: {
		id: 'resetPassword.sendCode',
		defaultMessage: 'Stuur verificatie code',
	},
	login: {
		id: 'resetPassword.login',
		defaultMessage: 'of terug naar inloggen',
	},
});

interface FormValues {
	username: string;
}

interface Props {
	onSubmit: (values: FormValues) => void;
}

export const ResetPasswordForm: FC<Props> = ({ onSubmit }) => {
	const { formatMessage: f } = useIntl();
	const { handleSubmit, register, errors } = useForm<FormValues>();
	const [loading, setLoading] = useState<boolean>(false);

	return (
		<form
			onSubmit={handleSubmit(async values => {
				try {
					setLoading(true);
					await onSubmit(values);
				} catch {}

				setLoading(false);
			})}>
			<div className="mb-3">
				<label>{f(messages.email)}</label>
				<input
					name="username"
					className="border w-full p-3"
					ref={register({
						required: f(formMessages.requiredField),
						pattern: {
							value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
							message: f(formMessages.invalidEmail),
						},
					})}
				/>

				{errors.username && <p className="input-error">{errors.username.message}</p>}
			</div>

			<button
				type="submit"
				className="mt-3 font-medium py-3 px-4 rounded text-md shadow-md cursor-pointer font-display bg-primary text-white w-full">
				{loading ? (
					<i className="bx bx-loader-alt bx-spin text-2xl"></i>
				) : (
					f(messages.sendCode)
				)}
			</button>

			<div className="text-center pt-2">
				<Link to="/auth/login" className="text-sm text-gray-600 p-1">
					{f(messages.login)}
				</Link>
			</div>
		</form>
	);
};
