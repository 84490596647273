import { RouteComponentProps } from '@reach/router';
import { navigate } from 'gatsby-plugin-locale';
import React, { FC } from 'react';
import { useAuthContext } from '../../hooks/useAuthContext';

interface Props extends RouteComponentProps {
	component: FC<any>;
}

export const PublicRoute: FC<Props & any> = ({ component: Component, ...rest }) => {
	const { loggedIn } = useAuthContext();
	if (loggedIn) {
		navigate('/');
		return null;
	}
	return <Component {...rest} />;
};
