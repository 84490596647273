import { RouteComponentProps } from '@reach/router';
import { Auth } from 'aws-amplify';
import { defineMessages, navigate, useIntl } from 'gatsby-plugin-locale';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { AuthLayout } from './AuthLayout';
import { ErrorMessage } from './components/ErrorMessage';
import { LoginForm } from './forms/LoginForm';
import { UpdateDetails } from './forms/UpdateDetails';
import { UpdatePassword } from './forms/UpdatePassword';

enum Steps {
	LOGIN = 'LOGIN',
	UPDATE_PASSWORD = 'UPDATE_PASSWORD',
	ADD_DETAILS = 'ADD_DETAILS',
}

const messages = defineMessages({
	title_LOGIN: {
		id: 'login.title_LOGIN',
		defaultMessage: 'Login',
	},
	title_ADD_DETAILS: {
		id: 'login.title_ADD_DETAILS',
		defaultMessage: 'Gegevens aanvullen',
	},
	title_UPDATE_PASSWORD: {
		id: 'login.title_UPDATE_PASSWORD',
		defaultMessage: 'Wachtwoord aanpassen',
	},
});

interface Props {
	locale: string;
}

export const Login: FC<RouteComponentProps & Props> = ({ locale }) => {
	const { formatMessage: f } = useIntl();
	const [step, setStep] = useState<Steps>(Steps.LOGIN);
	const [cognitoUser, setCognitoUser] = useState();
	const [attributes, setAttributes] = useState<object>();
	const [error, setError] = useState<any>();

	const loginAndNavigate = useCallback(async () => {
		navigate('/');
	}, []);

	const StepComponent = useMemo(() => {
		setError(null);
		if (step === Steps.LOGIN) {
			return (
				<LoginForm
					onSubmit={async ({ username, password }) => {
						try {
							setError(null);

							const user = await Auth.signIn(username, password);

							setCognitoUser(user);

							if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
								setStep(Steps.ADD_DETAILS);
							} else {
								loginAndNavigate();
							}
						} catch (err) {
							console.error(err);
							setError(err);
						}
					}}
				/>
			);
		} else if (step === Steps.ADD_DETAILS) {
			return (
				<UpdateDetails
					locale={locale}
					onSubmit={values => {
						setError(null);
						setAttributes(values);
						setStep(Steps.UPDATE_PASSWORD);
					}}
				/>
			);
		} else if (step === Steps.UPDATE_PASSWORD) {
			return (
				<UpdatePassword
					onSubmit={async ({ newPassword }) => {
						try {
							setError(null);
							await Auth.completeNewPassword(cognitoUser, newPassword, attributes);
							loginAndNavigate();
						} catch (err) {
							console.error(err);
							setError(err);
						}
					}}
				/>
			);
		}
	}, [step, locale, loginAndNavigate]);

	const dynamicKey = `title_${step}` as keyof typeof messages;

	return (
		<AuthLayout title={f(messages[dynamicKey])}>
			<ErrorMessage error={error} type={step} />
			{StepComponent}
		</AuthLayout>
	);
};
