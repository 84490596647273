import { navigate, RouteComponentProps } from '@reach/router';
import { Auth } from 'aws-amplify';
import { defineMessages, useIntl } from 'gatsby-plugin-locale';
import queryString from 'query-string';
import React, { FC, useMemo, useState } from 'react';
import { AuthLayout } from './AuthLayout';
import { ErrorMessage } from './components/ErrorMessage';
import { UpdatePassword } from './forms/UpdatePassword';

const messages = defineMessages({
	title: {
		id: 'resetPassword.title',
		defaultMessage: 'Paswoord resetten',
	},
	notValid: {
		id: 'resetPassword.notValid',
		defaultMessage: 'Deze link is niet geldig.',
	},
});

interface Props {
	locale: string;
}

export const ResetPassword: FC<RouteComponentProps & Props> = ({ locale, location }) => {
	const { formatMessage: f } = useIntl();
	const [error, setError] = useState<any>();
	const params = queryString.parse(location?.search || '') as {
		confirmation_code: string;
		email: string;
	};

	const StepComponent = useMemo(() => {
		setError(null);

		if (!params.confirmation_code && !params.email) {
			return (
				<div className="text-red-700 rounded relative mb-3 text-center" role="alert">
					{f(messages.notValid)}
				</div>
			);
		} else {
			return (
				<UpdatePassword
					onSubmit={async ({ newPassword }) => {
						try {
							setError(null);
							await Auth.forgotPasswordSubmit(
								params.email as string,
								params.confirmation_code as string,
								newPassword
							);
							navigate('/auth/login');
						} catch (err) {
							console.error(err);
							setError(err);
						}
					}}
				/>
			);
		}
	}, [locale]);

	return (
		<AuthLayout title={f(messages.title)}>
			<ErrorMessage error={error} />
			{StepComponent}
		</AuthLayout>
	);
};
