import { Router } from '@reach/router';
import React, { FC } from 'react';
import { ForgotPassword } from '../components/auth/ForgotPassword';
import { Login } from '../components/auth/Login';
import { ResetPassword } from '../components/auth/ResetPassword';
import { PublicRoute } from '../components/navigation/PublicRoute';
import { GatsbyPageProps } from '../declarations';

const Auth: FC<GatsbyPageProps> = ({ pageContext: { locale } }) => {
	return (
		<Router basepath="/auth">
			<PublicRoute path="/login" component={Login} locale={locale} />
			<PublicRoute path="/forgot-password" component={ForgotPassword} locale={locale} />
			<PublicRoute path="/reset-password" component={ResetPassword} locale={locale} />
		</Router>
	);
};

export default Auth;
