import { defineMessages, useIntl } from 'gatsby-plugin-locale';
import React, { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { formMessages } from '../../../utils/intl';

const messages = defineMessages({
	givenName: {
		id: 'updateDetails.givenName',
		defaultMessage: 'Wat is je voornaam?',
	},
	company: {
		id: 'updateDetails.company',
		defaultMessage: 'Waar werk je?',
	},
	locale: {
		id: 'updateDetails.locale',
		defaultMessage: 'Wat is je voorkeurtaal?',
	},
	button: {
		id: 'updateDetails.button',
		defaultMessage: 'Update gegevens',
	},
});

interface FormValues {
	company: string;
	given_name: string;
	locale: string;
}

interface Props {
	onSubmit: (values: FormValues) => void;
	locale: string;
}

export const UpdateDetails: FC<Props> = ({ onSubmit, locale }) => {
	const { formatMessage: f } = useIntl();
	const { handleSubmit, register, errors } = useForm<FormValues>();
	const [loading, setLoading] = useState<boolean>(false);

	return (
		<form
			onSubmit={handleSubmit(async values => {
				try {
					setLoading(true);
					await onSubmit({
						...values,
						locale,
					});
				} catch {}

				setLoading(false);
			})}>
			<div className="mb-3">
				<label>{f(messages.givenName)}</label>
				<input
					name="given_name"
					className="border w-full p-3"
					ref={register({
						required: f(formMessages.requiredField),
					})}
				/>

				{errors.given_name && <p className="input-error">{errors.given_name.message}</p>}
			</div>

			<div className="mb-3">
				<label>{f(messages.company)}</label>
				<input
					name="custom:company"
					className="border w-full p-3"
					ref={register({
						required: f(formMessages.requiredField),
					})}
				/>

				{errors.company && <p className="input-error">{errors.company.message}</p>}
			</div>

			{/* <div className="mb-3">
				<label>{f(messages.locale)}</label>
				<select
					name="locale"
					className="block appearance-none w-full border text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
					defaultValue={locale.split('-')[0]}
					ref={register({
						required: f(formMessages.requiredField),
					})}>
					<option value="nl">Nederlands</option>
					<option value="fr">Français</option>
					<option value="en">English</option>
				</select>

				{errors.locale && <p className="input-error">{errors.locale.message}</p>}
			</div> */}

			<button
				type="submit"
				className="mt-3 font-medium py-3 px-4 rounded text-md shadow-md cursor-pointer font-display bg-primary text-white w-full">
				{loading ? (
					<i className="bx bx-loader-alt bx-spin text-2xl"></i>
				) : (
					f(messages.button)
				)}
			</button>
		</form>
	);
};
