import { Link, useIntl, defineMessages } from 'gatsby-plugin-locale';
import React, { FC } from 'react';
import Obfuscate from 'react-obfuscate';
import logo from '../../assets/images/intensio_logo.svg';
import { support } from '../../../config/website';

export interface Props {
	title: string;
}

const messages = defineMessages({
	contactSupport: {
		id: 'auth.contactSupport',
		defaultMessage: 'Bij problemen met deze website kan je ons bereiken via {email}',
	},
});

export const AuthLayout: FC<Props> = ({ children, title }) => {
	const { formatMessage: f } = useIntl();
	return (
		<div className="text-base font-normal text-gray-900">
			<div className="bg-gray-200 h-full w-full z-0 absolute top-0"></div>
			<div className="container mx-auto p-8 z-10 relative mt-10">
				<div className="mx-auto max-w-md">
					<div className="py-10 text-center">
						<Link to="/">
							<img className="inline" src={logo} alt="intensio logo" width={240} />
						</Link>
					</div>

					<div className="bg-white rounded-lg shadow">
						<div className="py-6 px-10 font-medium font-display text-xl text-center border-b-2 text-secondary">
							{title}
						</div>

						<div className="px-10 pt-6 pb-6">{children}</div>
					</div>
					<div className="text-center pt-2 text-sm text-gray-600 px-2">
						{f(messages.contactSupport, {
							email: <Obfuscate element="span" email={support} />,
						})}
					</div>
				</div>
			</div>
		</div>
	);
};
