import React, { FC } from 'react';
import { defineMessages, useIntl } from 'gatsby-plugin-locale';

export interface Props {
	error?: any;
	type?: string;
}

const messages = defineMessages({
	NotAuthorizedException: {
		id: 'NotAuthorizedException',
		defaultMessage: 'Email of wachtwoord zijn incorrect',
	},
	GET_VERIFICATION_CODE_NotAuthorizedException: {
		id: 'GET_VERIFICATION_CODE_NotAuthorizedException',
		defaultMessage: 'Het is niet mogelijk om deze gebruiker zijn wachtwoord te resetten',
	},
	UPDATE_PASSWORD_NotAuthorizedException: {
		id: 'UPDATE_PASSWORD_NotAuthorizedException',
		defaultMessage: 'Deze sessie is vervallen. Probeer het opnieuw',
	},
	UserNotFoundException: {
		id: 'UserNotFoundException',
		defaultMessage: 'Deze gebruiker bestaat niet',
	},
	LimitExceededException: {
		id: 'LimitExceededException',
		defaultMessage: 'Je hebt deze actie al teveel geprobeerd. Probeer het later opnieuw.',
	},
	somethingWentWrong: {
		id: 'somethingWentWrong',
		defaultMessage: 'Er is iets misgegaan',
	},
	ExpiredCodeException: {
		id: 'ExpiredCodeException',
		defaultMessage: 'Deze code is reeds gebruikt of vervallen.',
	},
});

export const ErrorMessage: FC<Props> = ({ error, type }) => {
	const { formatMessage: f } = useIntl();

	if (!error) {
		return null;
	}

	let message = f(messages.somethingWentWrong);

	if (error.code) {
		const code = error.code as keyof typeof messages;
        const translatedMessage = (messages as any)[`${type}_${code}`] || messages[code];
                
		if (translatedMessage) {
			message = f(translatedMessage);
		}
	}

	return (
		<div
			className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-3"
			role="alert">
			{message}
		</div>
	);
};
