import React, { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { defineMessages, useIntl } from 'gatsby-plugin-locale';
import { formMessages } from '../../../utils/intl';

const messages = defineMessages({
	newPassword: {
		id: 'updatePassword.newPassword',
		defaultMessage: 'Nieuw paswoord',
	},
	confirmPassword: {
		id: 'updatePassword.confirmPassword',
		defaultMessage: 'Bevestig paswoord',
	},
	confirmNewPassword: {
		id: 'updatePassword.confirmNewPassword',
		defaultMessage: 'Nieuw paswoord bevestigen',
	},
	passwordContraints: {
		id: 'updatePassword.passwordContraints',
		defaultMessage:
			'Een paswoord moet bestaan uit minsten 10 tekens, en moet minstens 1 cijfer hebben.',
	},
});

interface FormValues {
	newPassword: string;
	confirmPassword: string;
}

interface Props {
	onSubmit: (values: FormValues) => void;
}

export const UpdatePassword: FC<Props> = ({ onSubmit }) => {
	const { formatMessage: f } = useIntl();
	const { handleSubmit, register, errors, getValues } = useForm<FormValues>();
	const [loading, setLoading] = useState<boolean>(false);

	return (
		<form
			onSubmit={handleSubmit(async values => {
				try {
					setLoading(true);
					await onSubmit(values);
				} catch {}

				setLoading(false);
			})}>
			<div className="mb-3">
				<label>{f(messages.newPassword)}</label>
				<input
					type="password"
					name="newPassword"
					className="border w-full p-3"
					ref={register({
						required: f(formMessages.requiredField),
						minLength: {
							value: 10,
							message: f(formMessages.passwordMustContainsLength, { amount: 10 }),
						},
						validate: value =>
							/\d/.test(value) || f(formMessages.passwordMustContainNumeric),
					})}
				/>
				<div className="text-gray-500 text-sm py-1 block">
					{f(messages.passwordContraints)}
				</div>

				{errors.newPassword && <p className="input-error">{errors.newPassword.message}</p>}
			</div>
			<div className="mb-3">
				<label>{f(messages.confirmPassword)}</label>
				<input
					type="password"
					name="confirmPassword"
					className="border w-full p-3"
					ref={register({
						required: f(formMessages.requiredField),
						validate: confirmPassword =>
							confirmPassword === getValues().newPassword ||
							f(formMessages.passwordMatch),
					})}
				/>

				{errors.confirmPassword && (
					<p className="input-error">{errors.confirmPassword.message}</p>
				)}
			</div>

			<button
				type="submit"
				className="mt-3 font-bold py-3 px-4 rounded text-md shadow-md cursor-pointer font-display bg-primary text-white w-full">
				{loading ? (
					<i className="bx bx-loader-alt bx-spin text-2xl"></i>
				) : (
					f(messages.confirmNewPassword)
				)}
			</button>
		</form>
	);
};
